<template>
  <input
    :id="id"
    :class="inputClasses"
    :type="type"
    :value="localValue"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    @keyup="emit('keyup', $event)"
    @input="handleInput"
    @blur="handleBlur"
    @click="emit('click', $event)"
    @focus="emit('focus', $event)"
  >
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from 'vue'
import type { FormFormatterFunc, FormIcon, FormVariant } from './types'

const props = withDefaults(defineProps<{
  id?: string
  value?: string
  modelValue?: string
  type?: InputTypeHTMLAttribute
  placeholder?: string
  trim?: boolean
  required?: boolean
  small?: boolean
  state?: boolean
  readonly?: boolean
  disabled?: boolean
  variant?: FormVariant
  icon?: FormIcon
  formatter?: FormFormatterFunc
  noFocusOutline?: boolean
}>(), {
  id: '',
  value: '',
  modelValue: '',
  type: 'text',
  placeholder: '',
  trim: false,
  required: false,
  small: false,
  state: true,
  readonly: false,
  disabled: false,
  variant: 'light',
  icon: '',
  formatter: (val: string) => val,
  noFocusOutline: false,
})

const emit = defineEmits<{
  'input': [value: string]
  'blur': [event: FocusEvent]
  'click': [event: MouseEvent]
  'focus': [event: FocusEvent]
  'keyup': [event: KeyboardEvent]
  'update:modelValue': [value: string]
}>()

const inputClasses = computed(() => {
  return [
    'zform-control',
    `zform-control-${props.variant}`,
    props.icon ? `zform-control-icon-${props.icon}` : '',
    {
      'zform-control-small': props.small,
      'zform-control-invalid': props.state === false,
      'zform-control-no-focus-outline': props.noFocusOutline,
    },
  ]
})

const localValue = ref(props.modelValue || props.value)

watch(() => props.modelValue, (newVal: string) => {
  localValue.value = formatValue(newVal)
})

watch(() => props.value, (newVal: string) => {
  localValue.value = formatValue(newVal)
})

function handleInput(event: Event) {
  const { value } = event.target as HTMLInputElement
  const formattedValue = formatValue(value)
  localValue.value = formattedValue

  const modifiedValue = modifyValue(formattedValue)
  emit('update:modelValue', modifiedValue)
  emit('input', modifiedValue)
}

function formatValue(val: string) {
  const sValue = val.toString()
  return props.formatter(sValue)
}

function modifyValue(val: string) {
  const sValue = val.toString()
  if (props.trim) {
    return sValue.trim()
  }
  return sValue
}

function handleBlur(event: FocusEvent) {
  const { value } = event.target as HTMLInputElement
  const formattedValue = formatValue(value)
  const modifiedValue = modifyValue(formattedValue)
  localValue.value = modifiedValue
  emit('blur', event)
}
</script>
